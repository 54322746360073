import { tCarousel } from '../types';
import i18n from '../i18n';

const carousels: tCarousel[] = [
  {
    imagePath: '/images/carousels/carousel-1.png',
    caption: 'Case Impian II'
  },
  {
    imagePath: '/images/carousels/carousel-2.png',
    caption: 'Lot 40'
  },
  {
    imagePath: '/images/carousels/carousel-3.png',
    caption: 'Taman Aman Alor'
  }
]

export default carousels
