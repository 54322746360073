
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import WebRoutes from './routes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import 'react-multi-carousel/lib/styles.css';
import 'react-tooltip/dist/react-tooltip.css'
import 'yet-another-react-lightbox/styles.css';
import './styles/main.css';

library.add(fas);
library.add(fab);

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <WebRoutes />
    </I18nextProvider>
  );
}

export default App;
