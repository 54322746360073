import { tProject } from '../types';
import i18n from '../i18n';

const WIDTH = 1680;
const HORIZONTAL_HEIGHT = 935;
const VERTICAL_HEIGHT = 2380;

const projects: tProject[] = [
  {
    key: 'ongoing-project',
    name: i18n.t('ongoingProjecs'),
    projects: [
      {
        id: 'b686b9cd-346f-4ab0-8aa4-4a53121461b7',
        title: 'Case Impian II',
        subTitle: '',
        description: 'Spacious double-storey homes blending comfort and modern living! Perfect for big families, with bumi & international lots available—plus special discounts for bumi buyers. Don’t miss out on your dream home! Conveniently located just 9 minutes from Lotus\'s Bandar Puteri Jaya, with schools, hospitals, and Econsave nearby—everything you need is within reach!',
        location: 'Sungai Petani, Kedah',
        gdv: '23000000',
        contract: '2025-2027',
        tags: ['TeresHouse', 'DoubleStorey'],
        cover: '/images/projects/covers/casa-impian.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-1.png',
            src: 'ci-dd-terrace-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-2.png',
            src: 'ci-dd-terrace-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-fp-1.png',
            src: 'ci-dd-terrace-fp-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-3.png',
            src: 'ci-dd-terrace-thumb-3.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-fp-2.png',
            src: 'ci-dd-terrace-fp-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-4.png',
            src: 'ci-dd-terrace-thumb-4.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ci-dd-terrace-5.png',
            src: 'ci-dd-terrace-thumb-5.png',
            tags: [],
          }
        ]
      }
    ]
  },
  {
    key: 'incoming-project',
    name: i18n.t('incomingProjecs'),
    projects: [
      {
        id: '36cca13f-7144-4294-bd81-5702be7c0d6a',
        title: 'Taman Aman Alor Setar',
        subTitle: '',
        description: 'Coming Soon!',
        location: 'Alor Setar, Kedah',
        gdv: '7200000',
        contract: '2026-2029',
        cover: '/images/projects/covers/taman-aman.png',
        tags: ['Teres2Tingkat', 'TeresHouse', '1Tingkat', 'SemiD', 'RumahBerkembar'],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ta-bungalow-dd.png',
            src: 'ta-bungalow-dd-thumb.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ta-bungalow-dd-1.png',
            src: 'ta-bungalow-dd-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ta-semid-dd-1.png',
            src: 'ta-semid-dd-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ta-semid-dd.png',
            src: 'ta-semid-dd-thumb.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ta-terrance-dd.png',
            src: 'ta-terrance-dd-thumb.png',
            tags: [],
          }
        ]
      }
    ]
  },
  {
    key: 'completed-project',
    name: i18n.t('completedProjecs'),
    projects: [
      {
        id: '9a8d9037-b5d2-4b21-8b38-ecfbfc5df807',
        title: 'Lot 40',
        subTitle: '',
        description: 'Lot 40, a whole new project to be built on Alor Star. Located on the near of Bus Terminal Shahab Perdana, and Lebuh Raya Sultanah Bahiyah, have a very high value for invest and also residents. In front of the project location is famous business district, only few kilometers is Highway entrance. \nPopular shopping hotspots within a convenient distance include Jusco Shopping Centre, Tesco and Giant hypermarket. The homes are fitted with top quality finishing, sanitary ware and other fixtures. \nLot 40 is provide few type house for different purchaser choose, you can find out 2 Storey Semi-D, Bungalow or Terrace, also can purchase from us 2.5 Storey Terrace and Semi-D type houses. There is total 66 units ready for sales. \nDeluxe house type and the specification is ready for you, this project come to life, and come to enjoyable.',
        location: 'Alor Setar, Kedah',
        gdv: '',
        contract: '',
        tags: ['Lot 40', '2 Storey Semi-D', 'Bungalow','Terrace'],
        cover: '/images/projects/covers/lot-40.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-1.png',
            src: 'lot-40-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-2.png',
            src: 'lot-40-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-3.png',
            src: 'lot-40-thumb-3.png',
            tags: [],
          }
        ]
      },
      {
        id: 'aae6d1c0-224f-4a6c-8ce0-2d6defeebdbb',
        title: 'Lot 40 - II',
        subTitle: '',
        description: 'Lot 40 II, only 9 unit terrace house to be built after first phase completed in Alor Star. 2 kind of typeof house for different purchaser choose, you can find out 2 Storey Terrace, also can purchase from us 2.5 Storey Terrace for corner lot. Deluxe house type and the specification is ready for you, this project come to life, and come to enjoyable.',
        location: 'Alor Setar, Kedah',
        gdv: '',
        contract: '',
        tags: ['Lot 40 II', 'Terrace'],
        cover: '/images/projects/covers/lot-40.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-1.png',
            src: 'lot-40-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-2.png',
            src: 'lot-40-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-3.png',
            src: 'lot-40-thumb-3.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-gf-fp.png',
            src: 'lot-40-gf-fp-thumb.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-ff-fp.png',
            src: 'lot-40-ff-fp-thumb.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'lot-40-sf-fp.png',
            src: 'lot-40-sf-fp-thumb.png',
            tags: [],
          }
        ]
      },
      {
        id: '99449418-b3f6-4d9d-b6c5-d445fb89a6f9',
        title: 'Casa Impian I',
        subTitle: '',
        description: 'Casa Impian Located on Sungai Petani Bandar Utama, and it’s one of our Town Concept series project. Casa Impian includes 5 type houses, Thara (9unit), Alya (148unit), Nila (203 unit), Diya (100 unit) and Nawa (329unit). Total 789 units was built on the Bandar Utama. And now, the whole project for terrace house all sold out within 3 years, only left not more than 15 vacant lot for bungalow house. Come and join us, the good investment project, a great pricing and only for SP Baiduri Bungalow lot!',
        location: 'Sungai Petani, Kedah',
        gdv: '',
        contract: '',
        tags: ['Town Concept', 'Bungalow'],
        cover: '/images/projects/covers/casa-impian.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'casa-impian-1.png',
            src: 'casa-impian-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'casa-impian-2.png',
            src: 'casa-impian-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'casa-impian-3.png',
            src: 'casa-impian-thumb-3.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'casa-impian-4.png',
            src: 'casa-impian-thumb-4.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'casa-impian-5.png',
            src: 'casa-impian-thumb-5.png',
            tags: [],
          }
        ]
      },
      {
        id: '54c1ad58-c72a-4807-99a8-e67f08a1dea4',
        title: 'Taman Universiti Jaya 2B',
        subTitle: '',
        description: 'Taman Universiti Jaya Phase 2B is the second project after 2A completed sold Semeling, Sg. Petani Kedah. We as a SPNB royalty partner, construc and develope according to the benchmarks set by the success of previous project, the project is expected to continue to excel in terms of the quality of its construction.',
        location: 'Sungai Petani, Kedah',
        gdv: '',
        contract: '',
        tags: ['SPNB royalty partner'],
        cover: '/images/projects/covers/taman-universiti-jaya.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-2b-1.png',
            src: 'tuj-2b-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-2b-2.png',
            src: 'tuj-2b-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-2b-3.png',
            src: 'tuj-2b-thumb-3.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-2b-4.png',
            src: 'tuj-2b-thumb-4.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-2b-5.png',
            src: 'tuj-2b-thumb-5.png',
            tags: [],
          }
        ]
      },
      {
        id: '3eb3c53d-4e79-4ed0-b01d-b6748bbd3908',
        title: 'Impiana 62B',
        subTitle: '',
        description: 'Situated on the strategy location in Sungai Petani, part of Bandar Utama Sungai Petani. Impiana 62 a stylish Single Storey Semi-Detached house, in front of SMK Bandar Puteri Jaya. Impiana 62 also one of the project to earn a subsidies program from PPA1M. The project seperate by 2 phase, total 62 unit 1 Storey Semi-Detached House, 3 unit 1 Storey Terrace House (launch soon) a,d also 2 Storey Shop Office (launch woon). Impiana 62 are within main road of Bandar Puteri Jaya, link directly to Sidam Kiri, and town of Sg. Petani.',
        location: 'Sungai Petani, Kedah',
        gdv: '',
        contract: '',
        tags: ['Single Storey Semi-Detached house'],
        cover: '/images/projects/covers/impiana-62b.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'impiana-62-1.png',
            src: 'impiana-62-thumb-1.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'impiana-62-2.png',
            src: 'impiana-62-thumb-2.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'impiana-62-3.png',
            src: 'impiana-62-thumb-3.png',
            tags: [],
          }
        ]
      },
    ]
  }
]

export default projects;