import { tRoutes } from '../types';
import i18n from '../i18n';

const routesConfig: tRoutes[] = [
  {
    key: 'home-route',
    title: i18n.t('home'),
    url: '/',
    isEnabled: true,
    component: 'Home',
  },
  {
    key: 'about-us-route',
    title: i18n.t('aboutus'),
    url: '/aboutus',
    isEnabled: true,
    component: 'AboutUs',
  },
  {
    key: 'service-route',
    title: i18n.t('ourService'),
    url: '/services',
    isEnabled: true,
    component: 'Services',
  },
  {
    key: 'project-route',
    title: i18n.t('project'),
    url: '/project/:projectType',
    isEnabled: true,
    component: 'Project',
  },
  {
    key: 'project-detail-route',
    title: i18n.t('project'),
    url: '/project/:projectType/:id',
    isEnabled: true,
    component: 'Project',
  },
  {
    key: 'contact-us-route',
    title: i18n.t('contactUs'),
    url: '/contact',
    isEnabled: true,
    component: 'ContactUs'
  },
  {
    key: 'error-404-route',
    title: i18n.t('error404'),
    url: '*',
    isEnabled: true,
    component: 'Error404',
  },
];

export default routesConfig
