import { tService } from '../types';
import i18n from '../i18n';

const services: tService[] = [
  {
    key: 'house-loan-key',
    iconName: 'landmark',
    title: i18n.t('houseLoanApplyTitle'),
    description: i18n.t('houseLoanApplyDescription')
  },
  {
    key: 'project-consult-key',
    iconName: 'newspaper',
    title: i18n.t('projectConsultTitle')  ,
    description: i18n.t('projectConsultDescription')
  },
  {
    key: 'legal-consult-key',
    iconName: 'comment',
    title: i18n.t('legalFeesConsultTitle'),
    description: i18n.t('legalFeesConsultDescription')
  }
]

export default services
