import React from 'react';
import i18n from '../../i18n';

const ContactUs: React.FC = () => {
  return(
    <div className='mx-auto min-h-full w-full max-w-7xl bg-white p-7 px-5 md:p-6 lg:p-6 animate-fade animate-delay-200 animate-once overflow-auto'>
      <div className='font-extrabold text-center uppercase text-2xl lg:text-4xl mt-5 mb-10'>{ i18n.t('contactTitle') }</div>
      <div className='font-medium text-center text-md lg:text-lg'>{ i18n.t('contactContent') }</div>
      <div className='text-center py-5'>
        <div className="text-lg">
          <div className='font-extrabold pr-x'>Address: </div>
          No.7&8, Jalan Cempaka 1/1, Persiaran Cempaka, Bandar Aman Jaya, Sungai Petani, 08000 Kedah
        </div>
        <div className="text-lg">
          <div className='font-extrabold pr-x'>Contact Us: </div>
          04-4413818,  04-4417818
        </div>
        <div className="text-lg">
          <div className='font-extrabold pr-x'>Email: </div>
          <a className='underline text-blue-800 font-medium' href='mailto:spbaiduri@gmail.com'>spbaiduri@gmail.com</a>
        </div>
      </div>
      <div className='text-center border border-red-900'>
        <iframe src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d992.5716979798913!2d100.507693!3d5.671612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304b29994b5b28e9%3A0x455cb1e01e7289d9!2sSP%20Baiduri%20Sdn%20Bhd!5e0!3m2!1sen!2sus!4v1735979639788!5m2!1sen!2sus'
          className='w-full h-80 lg:h-104 '
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'/>
      </div>
    </div>
  );
}

export default React.memo(ContactUs);
