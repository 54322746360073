import { tNavigations } from '../types';
import i18n from '../i18n';

const navigations: tNavigations[] = [
  {
    key: 'home-route',
    title: i18n.t('home'),
    url: '/',
    isEnabled: true,
    component: 'Home',
    subNavigations: []
  },
  {
    key: 'about-us-route',
    title: i18n.t('aboutUs'),
    url: '/aboutus',
    isEnabled: true,
    component: 'AboutUs',
    subNavigations: []
  },
  {
    key: 'service-route',
    title: i18n.t('ourService'),
    url: '/services',
    isEnabled: true,
    component: 'Services',
    subNavigations: []
  },
  {
    key: 'project-route',
    title: i18n.t('project', {count: 3}),
    url: '',
    isEnabled: true,
    component: '',
    subNavigations: [
      {
        key: 'ongoing-project-route',
        title: i18n.t('ongoingProject'),
        url: '/project/ongoing',
        isEnabled: true,
        component: 'Project'
      },
      {
        key: 'incoming-project-route',
        title: i18n.t('incomingProject'),
        url: '/project/incoming',
        isEnabled: true,
        component: 'Project'
      },
      {
        key: 'completed-project-route',
        title: i18n.t('completedProject'),
        url: '/project/completed',
        isEnabled: true,
        component: 'Project'
      }
    ]
  },
  {
    key: 'contact-us-route',
    title: i18n.t('contactUs'),
    url: '/contact',
    isEnabled: true,
    component: 'ContactUs',
    subNavigations: []
  }
];

export default navigations
