import React from 'react';
import clsx from 'clsx';
import navigations from '../../../data/navigations';
import { tNavigations, tSubNavigations } from '../../../types';
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const MenuBar: React.FC = () => {
  return (
    <PopoverGroup className='hidden md:flex md:gap-x-12 lg:flex lg:gap-x-12'>
      {
        navigations.map((route: tNavigations, index: number) => {
          if (route.subNavigations.length === 0) {
            return (
              <a key={`link-${route.key}`} href={route.url} className={clsx(`text-lg font-bold text-gray-900 py-2 px-4 animate-fade animate-duration-2000 animate-delay-${index*100} animate-once`, `btn-${route.component.toLowerCase()}`)}>
                {route.title}
              </a>
            )
          } else {
            return (
              <Popover key={`popover-${route.key}`} className={`relative animate-fade animate-duration-2000 animate-delay-${index*100} animate-once`}>
                <PopoverButton className="flex items-center gap-x-1 text-lg font-bold text-gray-900 py-2 px-4">
                  {route.title}
                  <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
                </PopoverButton>
                <PopoverPanel
                  transition
                  className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="p-2">
                    {
                      (route.subNavigations.map((subRoute: tSubNavigations) => {
                        return (
                          <div key={`nav-${subRoute.key}`}
                            className="group relative flex items-center gap-x-6 rounded-lg p-2 text-lg font-bold hover:bg-gray-50" >
                            <a href={subRoute.url} className="block font-semibold text-gray-900">
                              { subRoute.title }
                              <span className="absolute inset-0" />
                            </a>
                          </div>
                        )
                      }))
                    }
                  </div>
                </PopoverPanel>
              </Popover>
            )
          }
        })
      }
    </PopoverGroup>
  )
}

export default MenuBar;