import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/Footer';

interface IBaseLayout {
  children: React.ReactNode
}

const BaseLayout:React.FC<IBaseLayout> = ({ children }: IBaseLayout) => {
  return (
    <div className='flex flex-col h-screen'>
      <div className='z-50'>
        <NavigationBar />
      </div>
      <div className='grow base-layout-content'>
        { React.cloneElement(children as React.ReactElement<any>)}
      </div>
      <div className='z-50'>
        <Footer />
      </div>
    </div>
  )
}

export default React.memo(BaseLayout)