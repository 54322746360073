import React from 'react';
import services from '../../data/services';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Services: React.FC = () => {
  return(
    <div className='mx-auto min-h-full w-full max-w-7xl bg-white p-7 px-5 md:p-6 lg:p-6 animate-fade animate-delay-200 animate-once overflow-auto'>
      <div className='font-extrabold text-center uppercase text-2xl lg:text-4xl mt-5 mb-20'>{ i18n.t('ourService') }</div>
      <div className='m-auto max-h-full max-w-7xl my-10 text-center font-medium text-md lg:text-xl '>
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-3">
          {
            services.map((service) => {
              return (
                <div key={`${service.key}`} className='max-w rounded-lg overflow-hidden shadow-lg border border-red-900 bg-white lg:max-w-sm animate-fade animate-delay-300'>
                  <div className='text-center p-14 text-red-900'>
                    <FontAwesomeIcon icon={['fas', service.iconName ]}  size={'6x'} />
                  </div>
                  <div className='px-6 py-4 border-t border-gray-100'>
                    <div className='font-bold text-2xl mb-2'>{service.title}</div>
                    <div className='text-gray-700 text-lg font-medium h-24'>{service.description}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default React.memo(Services);
