import React from 'react';
import { MenuBar, MobileMenuBar } from './components';
import { Bars3Icon } from '@heroicons/react/24/outline';

const NavigationBar: React.FC = () => {
  const [ mobileMenuOpen, setMobileMenuOpen ] = React.useState(false)

  return (
    <header className='bg-white shadow-xl border-b-2 border-red-900 h-24'>
      <nav aria-label="Global" className="mx-auto flex max-w-full items-center justify-between p-7 px-2 md:p-6 lg:p-6">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5 animate-fade animate-once">
            <span className="sr-only">Detik Ventures</span>
            <img alt="Detik Ventures" src="/images/logo/logo.png" className="h-10 w-auto md:h-12 lg:h-12" />
          </a>
        </div>
        <div className="flex pr-4 md:hidden lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="size-6" />
          </button>
        </div>
        <MenuBar />
        <MobileMenuBar mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      </nav>
    </header>
  )
}

export default NavigationBar