import React from 'react';
import i18n from '../../i18n';

const AboutUs: React.FC = () => {
  return(
    <div className='mx-auto min-h-full h-5/6 w-full max-w-7xl bg-white p-7 px-5 md:p-6 lg:p-6 animate-fade animate-delay-200 animate-once overflow-auto'>
      <div className='font-extrabold text-center uppercase text-2xl lg:text-4xl my-5'>{ i18n.t('aboutUs') }</div>
      <div className='mx-auto max-h-full max-w-3xl my-10 text-center font-medium text-md lg:text-xl '>
        <p className='mb-8 animate-fade animate-delay-300'>
          { i18n.t('aboutUsParagraph1') }

        </p>
        <p className='mb-8 animate-fade animate-delay-500'>
          { i18n.t('aboutUsParagraph2') }

        </p>
        <p className='mb-8 animate-fade animate-delay-700'>
          { i18n.t('aboutUsParagraph3') }
        </p>
      </div>
    </div>
  );
}

export default React.memo(AboutUs);
